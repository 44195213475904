import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import $ from "jquery"
import Metatags from "../components/metatags"
import Navbar from "../components/navbar"
import Gallery from "../components/gallery"
import MobGallery from "../components/mob-gallery-comp"
import Footer from "../components/footer"
import responsiveIframe from "../../static/import.jquery.responsiveiframe"


const GotessonsPage = ({ data }) => {

  const nothing = (a) =>{}
  nothing(responsiveIframe)

  const [windowWidth, setWindowWidth] = useState(0)

  const setVideoWindow = () => {
    setWindowWidth($(window).width())
  }

  useEffect(() => {
    $("#logo-wrapper").css({
      maxHeight: $(window).height() - $("#navbar").height(),
    })
    $("#shrFrm").responsiveIframe()
    setWindowWidth($(window).width())
    if (typeof window !== "undefined") {
      window.addEventListener("resize", setVideoWindow)
    }
  }, [])

  //first image
  let imgObj = {}
  imgObj[["childImageSharp"]] =
    data.brandImages.nodes[0][Object.keys(data.brandImages.nodes[0])[0]][0]

  return (
    <>
      <Metatags
        title="daucodesigns | götessons"
        description="detrás de cada producto, hay una historia"
        url="https://daucodesigns.com"
        image="https://daucodesigns.com/logo-daucodesigns.png"
        keywords="muebles, design"
      />
      <Navbar />
      {windowWidth >= 768 ? (
        <div id="logo-wrapper">
          <div
            className="brand-logo-wrapper"
            style={{
              top: "20px",
              left: "20px",
              boxShadow: "0 0 50px -1px rgb(0 0 0 / 13%)",
              zIndex: "10",
            }}
          >
            <GatsbyImage
              image={getImage(data.logoImage)}
              backgroundColor={false}
              alt="götessons"
              className="img-responsive"
              style={{ padding: "2px" }}
            />
          </div>
          <GatsbyImage
            image={getImage(imgObj)}
            backgroundColor={false}
            alt="götessons"
            style={{
              left: 0,
              top: 0,
            }}
          />
          <Gallery imagesArray={data.brandImages.nodes} company="götessons" />
        </div>
      ) : (
        <div style={{ marginTop: "56px" }}>
          <MobGallery
            imagesArray={data.brandImages.nodes}
            ratio={765 / 1460}
            company="götessons"
            windowWidth={windowWidth}
            leftArrow={data.leftArrow}
            rightArrow={data.rightArrow}
          />
          <div style={{ width: "100%" }}>
            <div
              style={{
                boxShadow: "0 0 50px -1px rgb(0 0 0 / 13%)",
                margin: "8px auto",
                width: "120px",
              }}
            >
              <GatsbyImage
                image={getImage(data.logoImage)}
                backgroundColor={false}
                alt="götessons"
                className="img-responsive"
                style={{ padding: "2px" }}
              />
            </div>
          </div>
        </div>
      )}
      <div className="brand-description">
        <div className="text-center">
          <h1>götessons</h1>
          <h2>emprendedores y innovadores para más de tres generaciones</h2>
        </div>
        <div className="height20" />
      </div>
      <div className="height40" />
      <div className="height40" />
      <div className="architonic-container">
        <iframe
          id="shrFrm"
          src="https://vsr.architonic.com/es/8200790/collections/3104037/1"
          style={{
            width: "100%",
            margin: 0,
            border: "none",
            overflow: "hidden",
          }}
          title="bla-station-iframe"
        ></iframe>
      </div>
      <div className="height40" />
      <div className="height40" />
      <Footer />
    </>
  )
}

export default GotessonsPage

export const query = graphql`
  {
    logoImage: file(relativePath: { eq: "logos/gotessons.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    leftArrow: file(relativePath: { eq: "left.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    rightArrow: file(relativePath: { eq: "right.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    brandImages: allFile(
      filter: { relativePath: { regex: "/brands/gotessons/" } }
      sort: { fields: relativePath }
    ) {
      nodes {
        childrenImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
    }
  }
`
